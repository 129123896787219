import { createContext, useContext } from 'react'

import { z } from 'zod'

type FeatureValue = 'true' | 'false'

export const FeaturesSchema = z.object({
  FEATURE_PATIENT_ACCOUNTS: z.enum(['true', 'false']).optional(),
  FEATURE_REQUEST_HISTORY: z.enum(['true', 'false']).optional(),
  FEATURE_REQUEST_FILTERS: z.enum(['true', 'false']).optional(),
})

export type Features = z.infer<typeof FeaturesSchema>

// Get features from environment variables and URL query parameters
// query parameters take precedence over environment variables
export function getFeatures(url?: string) {
  const featuresFromEnv = getFeaturesFromEnv()
  const featuresFromUrl =
    url && process.env.ENVIRONMENT !== 'prod' ? getFeaturesFromUrl(url) : {}

  return { ...featuresFromEnv, ...featuresFromUrl }
}

function isFeatureKey(key: string): key is keyof Features {
  return Object.keys(FeaturesSchema.shape).includes(key)
}

export function getFeaturesFromEnv() {
  const features = Object.keys(FeaturesSchema.shape).reduce<Features>(
    (acc, key) => {
      const value = process.env[key]
      if (isFeatureKey(key) && typeof value === 'string') {
        acc[key] = value as FeatureValue
      }
      return acc
    },
    {},
  )

  const parsed = FeaturesSchema.safeParse(features)
  if (parsed.success === false) {
    console.error(
      '❌ Invalid feature env variables:',
      parsed.error.flatten().fieldErrors,
    )
    throw new Error('Invalid feature env variables')
  }

  return parsed.data
}

export function getFeaturesFromUrl(url: string) {
  const { searchParams } = new URL(url)

  const features: Features = {}
  for (const [key, value] of searchParams.entries()) {
    const formattedKey = key.toUpperCase() // URL query parameters are case-insensitive
    if (isFeatureKey(formattedKey) && typeof value === 'string') {
      features[formattedKey] = value as FeatureValue
    }
  }

  const parsed = FeaturesSchema.safeParse(features)
  if (parsed.success === false) {
    console.error(
      '❌ Invalid feature url variables:',
      parsed.error.flatten().fieldErrors,
    )
    return {} as Features
  }

  return parsed.data
}

export function isFeatureEnabled(feature: keyof Features, url?: string) {
  const features = getFeatures(url)
  return features[feature] === 'true'
}

export const FeaturesContext = createContext<Features | null>(null)
export const FeaturesProvider = FeaturesContext.Provider
export const useFeatures = () => useContext(FeaturesContext)

export function useIsFeatureEnabled(feature: keyof Features) {
  const features = useFeatures()
  return features && features[feature] === 'true'
}
